import React from 'react';
import { AppContainerProps } from './App.types';
import { App } from './App.component';
import { useReactWindowSize } from 'src/hooks/useReactWindowSize';
import { useAuth } from 'src/hooks/useAuth';

const Container: React.FC<AppContainerProps> = () => {
  useReactWindowSize();
  useAuth();
  return <App />;
};

export { Container as App };
