import { VFC, useCallback, useContext, useEffect, useState } from 'react';
import { SearchHome } from './SearchHome.component';
import { useSelector } from 'react-redux';
import { RootState } from 'src';
import {
  SearchHomeContainerProps,
  SearchHomeProps,
  ShowMetaData,
} from './SearchHome.types';
import { array, object, string } from 'yup';
import { ServicesContext } from 'src/ServicesContext';
import * as R from 'ramda';

const validationSchema = object({
  query: string().required('Please enter your query.'),
  useChatGPT: array(string().oneOf(['true'])),
});

export const DAYS = Array(29)
  .fill(0)
  .map((_x, i) => i)
  .slice(4);

const getAllDays = () =>
  R.fromPairs(
    DAYS.map((num: number) => [num.toString().padStart(2, '0'), true]),
  );

const getDaysFromToday = () =>
  R.fromPairs(
    DAYS.filter((x) => x >= new Date().getDate()).map((num: number) => [
      num.toString().padStart(2, '0'),
      true,
    ]),
  );

const SearchHomeContainer: VFC<SearchHomeContainerProps> = (props) => {
  const { analytics } = useContext(ServicesContext);
  const isLoggedIn = useSelector<RootState, boolean>(
    ({ auth }) => auth.id !== null,
  );
  const [days, setDays] = useState(getDaysFromToday());
  const [remainingSearches, setRemainingSearches] = useState(0);
  const { cloud } = useContext(ServicesContext);
  const [response, setResponse] = useState<string>('');
  const [usedChatGPT, setUsedChatGPT] = useState<boolean>(false);
  const [queryMade, setQueryMade] = useState<boolean>(false);
  const [metaData, setMetaData] = useState<ShowMetaData[]>([]);

  const handleReset = useCallback<SearchHomeProps['onReset']>(() => {
    setQueryMade(false);
    setResponse('');
    setMetaData([]);
  }, []);

  const handleSubmit = useCallback<SearchHomeProps['onSubmit']>(
    async (values, _actions) => {
      const queryObject = {
        query: values.query,
        days: Object.keys(days).filter((day) => days[day]),
        useChatGPT: values.useChatGPT.length > 0,
      };
      analytics.recordEvent('search', queryObject);
      const response = await cloud.search(queryObject);
      setResponse(response.data.result);
      setQueryMade(true);
      setUsedChatGPT(response.data.usedChatGPT);
      setMetaData(response.data.metaData);
      setRemainingSearches(response.data.numberOfSearchesRemaining);
    },
    [analytics, cloud, days],
  );

  const handleDayClick = useCallback<SearchHomeProps['onDayClick']>(
    (day: number) => {
      const dayString = day.toString().padStart(2, '0');
      setDays((days) => ({ ...days, [dayString]: !days[dayString] }));
    },
    [setDays],
  );

  const handleSelectAll = useCallback<SearchHomeProps['onSelectAll']>(
    () => setDays(getAllDays()),
    [setDays],
  );

  const handleSelectFromToday = useCallback<SearchHomeProps['onSelectAll']>(
    () => setDays(getDaysFromToday()),
    [setDays],
  );

  const handleSelectNone = useCallback<SearchHomeProps['onSelectNone']>(
    () => setDays({}),
    [setDays],
  );

  useEffect(() => {
    cloud.getRemainingSearches().then((remainingSearches) => {
      setRemainingSearches(remainingSearches.data);
    });
  }, [cloud, remainingSearches, setRemainingSearches]);

  return (
    <SearchHome
      initialValues={{ query: '', useChatGPT: ['true'] }}
      validationSchema={validationSchema}
      remainingSearches={remainingSearches}
      isLoggedIn={isLoggedIn}
      response={response}
      onReset={handleReset}
      onSubmit={handleSubmit}
      onDayClick={handleDayClick}
      onSelectAll={handleSelectAll}
      onSelectNone={handleSelectNone}
      onSelectFromToday={handleSelectFromToday}
      usedChatGPT={usedChatGPT}
      queryMade={queryMade}
      showMetaData={metaData}
      days={days}
      {...props}
    />
  );
};

export { SearchHomeContainer as SearchHome };
