/*

Simple UI information, e.g. window width or if certain modes are toggled on / off.

*/
import { UIAction, ActionNames, UIState } from 'src/types';

const UI_DEFAULT: UIState = {
  windowWidth: document.body.clientWidth,
  windowHeight: document.body.clientHeight,
};

export const ui = (state = UI_DEFAULT, action: UIAction): UIState => {
  switch (action.type) {
    case ActionNames.UI_WINDOW_SIZE_SET:
      return {
        ...state,
        windowWidth: document.body.clientWidth,
        windowHeight: document.body.clientHeight,
      };
    case ActionNames.UNSET_ALL:
      return UI_DEFAULT;
    default:
      return state;
  }
};
