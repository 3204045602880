import { Constants } from 'src/constants';
import { Cloud } from 'src/services/Cloud';
import { getRandomUID } from 'src/utils/getRandomUID';
import Cookies from 'universal-cookie';

declare var mixpanel: {
  track: (eventName: string, params?: Record<string, unknown>) => void;
  identify: (userUID: string) => void;
  reset: () => void;
};

// Get the landing page on load from cookie if visited before, otherwise set cookie:
const cookies = new Cookies();

const CLIENT_IDENTIFIER_READ_FROM_COOKIES = cookies.get(
  Constants.CLIENT_IDENTIFIER_COOKIE_KEY,
);
const CLIENT_IDENTIFIER = CLIENT_IDENTIFIER_READ_FROM_COOKIES ?? getRandomUID();
if (!CLIENT_IDENTIFIER_READ_FROM_COOKIES) {
  cookies.set(Constants.CLIENT_IDENTIFIER_COOKIE_KEY, CLIENT_IDENTIFIER, {
    path: Constants.COOKIEPATH,
  });
}

// Analytics service
export class Analytics {
  cloud: Cloud;
  public constructor(cloud: Cloud) {
    this.cloud = cloud;
  }
  public async recordEvent(eventName: string, params?: any) {
    // Mixpanel:
    mixpanel.track(eventName, params);
  }
}
