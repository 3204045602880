import React from 'react';
import clsx from 'clsx';
import { Stack } from '@moonsifttech/design-system';
import styled from 'styled-components';

import { BlankButtonProps } from './BlankButton.types';

const UnstyledBlankButton = React.forwardRef<HTMLDivElement, BlankButtonProps>(
  ({ className, style, loading, onClick, children }, ref) => {
    return (
      <Stack
        ref={ref}
        className={clsx(className, loading && 'BlankButton-loading')}
        style={style}
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={6}
      >
        <button onClick={onClick}>{children}</button>
      </Stack>
    );
  },
);

export const BlankButton = styled(UnstyledBlankButton)`
  button {
    padding: 0px;
    color: var(--common-black);
    background: transparent;
    cursor: pointer;
    border: 0px;
  }

  svg {
    display: block;
  }

  &.BlankButton-loading button {
    cursor: wait;
  }
`;
