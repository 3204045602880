import queryString from 'query-string';
const queryResults = queryString.parse(window.location.search);

export const Constants = {
  HOMEPAGEURL: process.env.REACT_APP_HOMEPAGEURL,
  APIADDRESS: process.env.REACT_APP_MOONSIFTAPI_URL,
  STATICURL: process.env.REACT_APP_STATIC_URL,
  CLIENT_IDENTIFIER_COOKIE_KEY: 'client_identifier',
  COOKIEPATH: '/',
  RESULT_LOAD_INCREMENT_AMOUNT: 50,
  DOWNVOTES_PER_FEEDBACK_MODAL_SHOW_EVENT: 3,
  SHOW_IMAGE_WEIGHT: queryResults.showImageWeight === 'TRUE',
  MAX_RESULTS: 500,
  IMAGE_BUCKET_ADDRESS:
    'https://storage.googleapis.com/product_images_moonrose/',
  HOW_TO_USE_LINK: 'https://www.moonsift.com/ai/how-to-use',
  DEFAULT_IMAGE_WEIGHT: '0.7',
};
