import { ActionNames } from 'src/types';
import { AuthAction, AuthState } from 'src/types/models/auth.model';
/*

Just stores auth data directly from auth service (not the database!)

*/
const AUTH_DEFAULT: AuthState = {
  id: null,
  email: null,
  provider_name: null,
  set: false,
};

export const auth = (state = AUTH_DEFAULT, action: AuthAction): AuthState => {
  switch (action.type) {
    case ActionNames.AUTH_SET:
      return {
        id: action.payload.id,
        email: action.payload.email,
        provider_name: action.payload.provider_name,
        set: true,
      };
    case ActionNames.UNSET_ALL:
      return {
        id: null,
        email: null,
        provider_name: null,
        set: true,
      };
    default:
      return state;
  }
};
