import { FirebaseApp } from '@firebase/app';
import {
  getFirestore,
  Firestore,
  connectFirestoreEmulator,
  DocumentSnapshot,
  QueryDocumentSnapshot,
  getDoc,
  addDoc,
  updateDoc,
  setDoc,
  deleteDoc,
  doc,
  getDocs,
  CollectionReference,
  Query as FirebaseQuery,
  onSnapshot,
  DocumentData,
  QuerySnapshot,
  Timestamp,
  FirestoreError,
  getDocFromServer,
  collection,
} from 'firebase/firestore';

export type Query = FirebaseQuery | CollectionReference;

/*

The main database, NoSQL.

*/
export class DB {
  fs: Firestore;

  constructor(app: FirebaseApp) {
    this.fs = getFirestore(app);
    // Development Settings:
    if (process.env.REACT_APP_USE_LOCAL_FIRESTORE === 'TRUE') {
      connectFirestoreEmulator(this.fs, 'localhost', 8383);
    }
  }

  // Add functions here to get db docs + collection refs + queries:
  product = (productUID: string) => `/databases/product/products/${productUID}`;
  // Add functions here to perform actions on collections and docs:
  getDoc = (docRef: string, server: true | undefined = undefined) =>
    server
      ? getDocFromServer(doc(this.fs, docRef))
      : getDoc(doc(this.fs, docRef));
  getQuery = (queryIn: Query) => getDocs(queryIn);
  listenDoc = (
    docRef: string,
    callback: (x: DocumentSnapshot<DocumentData>) => void,
    onError: (error: FirestoreError) => void,
  ) => onSnapshot(doc(this.fs, docRef), callback, onError);
  listenQuery = (
    queryIn: Query,
    callback: (x: QuerySnapshot<DocumentData>) => void,
    onError: (error: FirestoreError) => void,
  ) => onSnapshot(queryIn, callback, onError);
  deleteDoc = (docRef: string) => deleteDoc(doc(this.fs, docRef));
  addDoc = (collectionRef: CollectionReference, data: any) =>
    addDoc(collectionRef, data);
  updateDoc = (docRef: string, data: any) =>
    updateDoc(doc(this.fs, docRef), data);
  setDoc = (docRef: string, data: any) =>
    setDoc(doc(this.fs, docRef), data, { merge: true });
  serverTimestamp = () => {
    return Timestamp.now();
  };
  collectionList = (userUID: string) =>
    collection(this.fs, `/databases/user_product_collection/${userUID}`);
}

// Types required elsewhere will be imported from here NOT from firebase-firestore so firebase is not
// an explicitly hard-coded dependency!
export type {
  DocumentData,
  QuerySnapshot,
  QueryDocumentSnapshot,
  DocumentSnapshot,
  FirestoreError as DBError,
  PartialWithFieldValue,
} from 'firebase/firestore';
export type ParserInput = DocumentSnapshot | QueryDocumentSnapshot;
export { Timestamp } from 'firebase/firestore';
