import { combineReducers } from 'redux';
import { History } from 'history';

import { RootDB } from 'src/types';

import {
  getBaseDBReducer,
  DEFAULT_QUERY_STATE,
  DEFAULT_DOC_STATE,
  DEFAULT_DOC_MAP_STATE,
  DEFAULT_QUERY_MAP_STATE,
} from './baseDB';
import { connectRouter } from 'connected-react-router';
import { ui } from './ui';
import { auth } from './auth.reducer';

// Add DB states here:!
const DEFAULT_STATE: RootDB = {
  product_doc: DEFAULT_DOC_STATE,
  product_map_doc: DEFAULT_DOC_MAP_STATE,
  product_query: DEFAULT_QUERY_STATE,
  product_map_query: DEFAULT_QUERY_MAP_STATE,
};

const reducers = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    db: getBaseDBReducer(DEFAULT_STATE),
    ui,
    auth,
  });

export default reducers;
