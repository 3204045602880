import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import clsx from 'clsx';
import { Helmet } from 'react-helmet';

import { RouterSwitch } from 'src/components/core/RouterSwitch';
import { AppProps } from './App.types';

// Scroll bars:
const GlobalStyle = createGlobalStyle<{ isScrollable: boolean }>`
  body {
    overflow-y: ${(props) => (props.isScrollable ? 'scroll' : 'hidden')};
    width:100%;
    height:100%;
  }
  img {
    margin-bottom:0rem;
  }
  ::-webkit-scrollbar {
    background: var(--common-white);
    border-left: 1px solid var(--common-white);
    box-sizing: border-box;
    width: 15px;
  }
  ::-webkit-scrollbar-thumb {
    background: var(--grey-4);
    border-radius: 8px;
    border: 3px solid transparent;
    background-clip: content-box;
    box-sizing: border-box;
    border-left: 5px solid transparent;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: var(--grey-3);
    border-radius: 8px;
    border: 3px solid transparent;
    background-clip: content-box;
    box-sizing: border-box;
    border-left: 5px solid transparent;
  }
`;

const Component: React.FC<AppProps> = () => {
  return (
    <div className={clsx('EverythingContainer', 'App-root')}>
      <GlobalStyle isScrollable={true} />
      <Helmet>
        <title>Moonsift</title>
      </Helmet>
      <div className="MainBGContainer">
        <RouterSwitch />
      </div>
    </div>
  );
};

export const App = styled(Component)`
  .MainBGContainer {
    color: default;
  }

  .EverythingContainer {
    width: 100%;
    height: 100%;
    font-size: 0px;
    text-align: center;
    top: 0px;
    left: 0px;
    background-color: white;
    color: black;
  }
`;
