import 'react-app-polyfill/stable';
// To support .allSettled explicitly since this is in the ECMA2020 spec, which createreactapp's polyfill doesn't detect yet:
import 'core-js/features/promise';

import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunkMiddleware from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import { ConnectedRouter, routerMiddleware } from 'connected-react-router';
import { ThemeProvider } from 'styled-components';
import {
  batmanTheme,
  CssBaseline,
  setAppElement,
} from '@moonsifttech/design-system';
import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material/styles';
import '@fontsource/roboto/700.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/300.css';
import '@fontsource/josefin-sans/700.css';
import '@fontsource/josefin-sans/300.css';
import '@moonsifttech/font-juana';

import reducers from './store/reducers';
import rootSaga from './store/sagas';
import { App } from './components/core/App';
import { setupServices } from './utils/setupServices';
import { ServicesContext } from './ServicesContext';
import { createBrowserHistory } from 'history';

// Load env vars
require('dotenv').config();

// MUI theme
const muiTheme = createTheme();

// This helps keep a11y on modal components that use react-modal
// http://reactcommunity.org/react-modal/accessibility/
setAppElement('#root');

// Setup services
const services = setupServices();

// Set up Redux store:
const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();
const store = createStore(
  reducers(history),
  composeWithDevTools(
    applyMiddleware(
      routerMiddleware(history),
      thunkMiddleware.withExtraArgument({ services, history }),
      sagaMiddleware,
    ),
  ),
);
sagaMiddleware.run(rootSaga);
export type RootState = ReturnType<typeof store.getState>;

// Render:
ReactDOM.render(
  <React.StrictMode>
    <ServicesContext.Provider value={services}>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <ThemeProvider theme={batmanTheme}>
            <MuiThemeProvider theme={muiTheme}>
              <CssBaseline />
              <App />
            </MuiThemeProvider>
          </ThemeProvider>
        </ConnectedRouter>
      </Provider>
    </ServicesContext.Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);
