import { ThunkAction } from 'redux-thunk';

import { RootState } from 'src/index';
import { BaseDBAction } from './models/basedb.model';
import { Services } from 'src/utils/setupServices';
import { History } from 'history';

// All actions are here to avoid conflicts:
export enum ActionNames {
  UNSET_ALL = 'UNSET_ALL',
  UI_WINDOW_SIZE_SET = 'UI_WINDOW_SIZE_SET',
  AUTH_SET = 'AUTH_SET',
  // only used with qualifying "kind" property:
  BASEDB_SET_DOC = 'BASEDB_SET_DOC',
  BASEDB_SET_LISTENER_DOC = 'BASEDB_SET_LISTENER_DOC',
  BASEDB_LISTEN_DOC = 'BASEDB_LISTEN_DOC',
  BASEDB_GET_DOC = 'BASEDB_GET_DOC',
  BASEDB_SET_QUERY = 'BASEDB_SET_QUERY',
  BASEDB_SET_LISTENER_QUERY = 'BASEDB_SET_LISTENER_QUERY',
  BASEDB_LISTEN_QUERY = 'BASEDB_LISTEN_QUERY',
  BASEDB_GET_QUERY = 'BASEDB_GET_QUERY',
  BASEDB_UNLISTEN_MAP = 'BASEDB_UNLISTEN_MAP',
  BASEDB_UNLISTEN_SINGLE = 'BASEDB_UNLISTEN_SINGLE',
  RESET_SEARCH_HOME = 'RESET_SEARCH_HOME',
}

// All actions:
export interface ActionType<T, P> {
  type: T;
  payload: P;
}

type UnsetAllAction = ActionType<ActionNames.UNSET_ALL, undefined>;

// So that we can have the overall action type as the input to all redux functions.
export type Action = BaseDBAction | UnsetAllAction;

// Serves to type thunk action creators in an easier manner
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  { services: Services; history: History },
  Action
>;
