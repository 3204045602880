import { VFC } from 'react';
import styled from 'styled-components';

import { SearchHomeProps } from './SearchHome.types';
import { Helmet } from 'react-helmet';
import { Formik, Form } from 'formik';
import {
  Typography,
  Stack,
  ControlledTextField,
  StandardButton,
  Link,
  ControlledCheckbox,
  FormControlLabel,
  ControlledFormGroup,
} from '@moonsifttech/design-system';
import { DAYS } from './SearchHome.container';
import clsx from 'clsx';
import { BlankButton } from 'src/components/core/BlankButton';
import { Constants } from 'src/constants';

const UnstyledSearchHome: VFC<SearchHomeProps> = ({
  className,
  style,
  onSubmit,
  validationSchema,
  initialValues,
  response,
  onDayClick,
  onSelectAll,
  onSelectNone,
  onSelectFromToday,
  onReset,
  usedChatGPT,
  queryMade,
  showMetaData,
  remainingSearches,
  days,
}) => {
  return (
    <>
      <Helmet>
        <title>FringeSift - Find Edinburgh Fringe shows with AI</title>
        <meta name="description" content="Find fringe shows" />
      </Helmet>
      <div className={className} style={style}>
        <div className="SearchHome-content">
          <Stack direction="column">
            <BlankButton onClick={onReset} className="SearchHome-topImage">
              <img
                className="SearchHome-logo"
                src={`${Constants.STATICURL}/logos/fringe_sift.svg`}
                alt="Fringesift"
              />
            </BlankButton>
            <Typography variant="primary.b26" component="h1">
              Find me shows with AI...
            </Typography>
            Days in August:
            <Stack className="SearchHome-dayButtonContainer" direction="row">
              {DAYS.map((day) => (
                <StandardButton
                  className={clsx(
                    'SearchHome-dayButton',
                    days[day.toString().padStart(2, '0')]
                      ? null
                      : 'SearchHome-dayButton-notSelected',
                  )}
                  key={day}
                  variant={
                    days[day.toString().padStart(2, '0')]
                      ? 'primary-black'
                      : 'primary-white'
                  }
                  onClick={() => onDayClick(day)}
                >
                  {day}
                </StandardButton>
              ))}
            </Stack>
            <Stack
              direction="row"
              spacing={16}
              className="SearchHome-selectAllNoneContainer"
            >
              <BlankButton onClick={onSelectAll}>
                <Typography variant="primary.r16" component="div">
                  Select all
                </Typography>
              </BlankButton>
              <BlankButton onClick={onSelectFromToday}>
                <Typography variant="primary.r16" component="div">
                  Select from today
                </Typography>
              </BlankButton>
              <BlankButton onClick={onSelectNone}>
                <Typography variant="primary.r16" component="div">
                  Select none
                </Typography>
              </BlankButton>
            </Stack>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ dirty, isSubmitting }) => (
                <Form className="SearchHome-form">
                  Text query to find shows:
                  <Stack
                    className="SearchHome-textboxContainer"
                    direction="row"
                    alignItems="center"
                    spacing={6}
                  >
                    <ControlledTextField
                      className="SearchHome-textbox"
                      id="query"
                      name="query"
                      placeholder="Type your query here"
                      fullWidth={true}
                      maxLength={400}
                      autoFocus
                    />
                  </Stack>
                  <Stack direction="row" className="SearchHome-chatGPTBox">
                    <Typography
                      variant="primary.m16"
                      component="p"
                      style={{ margin: '0px' }}
                    >
                      Use ChatGPT to summarise responses ({remainingSearches}{' '}
                      searches remaining today)
                    </Typography>
                    <ControlledFormGroup
                      className="SearchHome-checkboxes"
                      name="useChatGPT"
                    >
                      <FormControlLabel
                        label=""
                        labelPlacement="end"
                        value="true"
                        control={<ControlledCheckbox name="useChatGPT" />}
                      />
                    </ControlledFormGroup>
                  </Stack>
                  <Stack
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    <StandardButton
                      type="submit"
                      variant="primary-white"
                      disabled={!dirty}
                      loading={isSubmitting}
                    >
                      Search
                    </StandardButton>
                  </Stack>
                </Form>
              )}
            </Formik>
            <Stack>
              {queryMade ? (
                <Typography variant="primary.l16" component="div">
                  {usedChatGPT && (
                    <p
                      className="SearchHome-results"
                      dangerouslySetInnerHTML={{ __html: response }}
                    />
                  )}
                  {
                    <>
                      <Typography variant="primary.r16" component="div">
                        {usedChatGPT
                          ? 'Other potentially relevant shows:'
                          : 'Possible results (unfiltered by AI):'}
                      </Typography>
                      {showMetaData.map((x, i) => (
                        <Stack key={i} className="SearchHome-item">
                          <Typography variant="primary.l14" component="div">
                            <li>
                              {x.show_name} (
                              <Link to={x.event_url}>View on edfringe.com</Link>
                              ){' '}
                            </li>
                          </Typography>
                          <Typography variant="primary.l14" component="div">
                            <i>{x.description}</i>
                          </Typography>
                        </Stack>
                      ))}
                    </>
                  }
                </Typography>
              ) : (
                <div className="SearchHome-preText">
                  <Typography
                    variant="primary.l16"
                    component="div"
                    className="SearchHome-info"
                  >
                    ChatGPT results will be slower and are limited to 10 per
                    day.
                    <br />
                    <br />
                    Try queries with or without context e.g. "The middle ages"
                    or "Shows for start-up founders".
                  </Typography>
                  <Typography
                    variant="primary.l16"
                    component="div"
                    className="SearchHome-info"
                  >
                    Interested in joining a new Edinburgh AI meetup? Connect
                    with me on{' '}
                    <Link to="https://www.linkedin.com/in/davidwooduk">
                      LinkedIn
                    </Link>
                  </Typography>
                  <Stack className="SearchHome-expandable" />
                  <Typography
                    variant="primary.r16"
                    component="div"
                    className="SearchHome-minorText"
                  >
                    Created by{' '}
                    <Link to="https://www.linkedin.com/in/davidwooduk">
                      David Wood
                    </Link>
                    . Hosted by{' '}
                    <Link to="https://www.moonsift.com/">Moonsift</Link>.
                    Powered by vector database embeddings created using OpenAI
                    with responses summarised by ChatGPT3.5.
                  </Typography>
                </div>
              )}
            </Stack>
          </Stack>
        </div>
      </div>
    </>
  );
};

export const SearchHome = styled(UnstyledSearchHome)`
  width: 100%;
  min-height: 100vh;
  .SearchHome-content {
    display: flex;
    flex-direction: row;
    padding: 24px;
    max-width: 825px;
    margin: auto;
  }

  .SearchHome-logo {
    width: 200px;
  }

  .SearchHome-form {
    max-width: 825px;
    margin-top: 16px;
  }

  .SearchHome-selectAllNoneContainer {
    margin: 8px 0px;
    text-decoration: underline;
  }

  .SearchHome-dayButtonContainer {
    margin-top: 8px;
    flex-wrap: wrap;
  }

  .SearchHome-dayButton {
    min-width: 30px;
    padding: 4px;
    margin: 0.5px;
  }

  .SearchHome-item {
    margin-bottom: 8px;
  }

  .SearchHome-dayButton-notSelected {
    color: var(--grey-3);
  }

  .SearchHome-results {
    a {
      color: var(--common-black);
    }
    padding: 8px;
    border-radius: 8px;
    background: var(--grey-6);
    max-width: 825px;
  }

  .SearchHome-preText {
    margin-top: 24px;
  }

  .SearchHome-info {
    background: var(--grey-6);
    padding: 8px;
    border-radius: 8px;
    margin: 18px 0px;
  }

  .SearchHome-minorText {
    color: var(--grey-3);
  }

  .SearchHome-chatGPTBox {
    justify-content: flex-end;
    margin: auto;
    align-items: center;
  }

  .SearchHome-textboxContainer {
    margin-top: 8px;
  }

  .SearchHome-expandable {
    height: 24px;
    flex-grow: 1;
  }

  .SearchHome-topImage {
    display: flex;
    align-items: flex-start;
  }
`;
