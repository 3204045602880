import React from 'react';
import { Switch } from 'react-router-dom';
import { RouterSwitchProps } from './RouterSwitch.types';
import { SearchHome } from 'src/views/SearchHome';

export const RouterSwitch: React.FC<RouterSwitchProps> = () => {
  return (
    <Switch>
      {/* <Route path={''} component={SearchHome} /> */}
      <SearchHome />
    </Switch>
  );
};
