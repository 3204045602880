import { FirebaseApp } from 'firebase/app';
import {
  getAuth,
  Auth as FirebaseAuth,
  connectAuthEmulator,
  User,
  signInWithEmailAndPassword,
} from 'firebase/auth';
import queryString from 'query-string';

export class Auth {
  auth: FirebaseAuth;

  constructor(app: FirebaseApp) {
    const auth = getAuth(app);

    this.auth = auth;

    if (process.env.REACT_APP_USE_LOCAL_AUTH === 'TRUE') {
      connectAuthEmulator(auth, 'http://localhost:9099');
    }
    const queryDict = queryString.parse(window.location.search);
    if (
      typeof queryDict.email === 'string' &&
      typeof queryDict.password === 'string'
    ) {
      signInWithEmailAndPassword(auth, queryDict.email, queryDict.password);
    }
  }
}
export type UserObject = User;
