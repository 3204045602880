import { Firebase } from 'src/services/Firebase';
import { DB } from 'src/services/DB';
import { Cloud } from 'src/services/Cloud';

import { Services } from './setupServices.types';
import { Auth } from 'src/services/Auth';
import { Analytics } from 'src/services/Analytics';

export const setupServices = (): Services => {
  const app = new Firebase();
  const cloud = new Cloud(app.app);
  const db = new DB(app.app);
  const auth = new Auth(app.app);
  const analytics = new Analytics(cloud);

  return {
    db,
    cloud,
    auth,
    analytics,
  };
};
