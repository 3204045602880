import { FirebaseApp } from 'firebase/app';
import {
  getFunctions,
  Functions,
  connectFunctionsEmulator,
  httpsCallable,
} from 'firebase/functions';
import { ShowMetaData } from 'src/views/SearchHome';

/*

Misc (but important) cloud services, namely functions and uploading for storage.

*/
export class Cloud {
  functions: Functions;

  constructor(app: FirebaseApp) {
    this.functions = getFunctions(app, 'europe-west2');
    // Development Settings:
    if (process.env.REACT_APP_USE_LOCAL_FUNCTIONS === 'TRUE') {
      connectFunctionsEmulator(this.functions, 'localhost', 5001);
    }
  }

  search = (data: { query: string; days: string[]; useChatGPT: boolean }) =>
    httpsCallable<
      { query: string; days: string[]; useChatGPT: boolean },
      {
        result: string;
        usedChatGPT: boolean;
        metaData: ShowMetaData[];
        numberOfSearchesRemaining: number;
      }
    >(
      this.functions,
      'search',
    )(data);
  getRemainingSearches = () =>
    httpsCallable<{}, number>(this.functions, 'getRemainingSearches')();
}
