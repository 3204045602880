import { useEffect, useContext, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { ServicesContext } from 'src/ServicesContext';
import { UserObject } from 'src/services/Auth';
import { ActionNames } from 'src/types';
import { AuthAction } from 'src/types/models/auth.model';

/*

This hook manages authentication but does not load in any other data about the user from the database:

*/
export const useAuth = () => {
  const dispatch = useDispatch();
  const { auth } = useContext(ServicesContext);

  const onAuthChangeFunction = useCallback(
    (user: UserObject | null) => {
      const authUserUID = user === null ? null : user.uid;
      const authUserEmail = user === null ? null : user.email;
      const authUserProviderName =
        user !== null &&
        user.providerData &&
        user.providerData.length > 0 &&
        user.providerData[0].displayName
          ? user.providerData[0].displayName
          : null;
      console.log('authUserUID', authUserUID);
      dispatch<AuthAction>({
        type: ActionNames.AUTH_SET,
        payload: {
          id: authUserUID,
          email: authUserEmail,
          provider_name: authUserProviderName,
        },
      });
    },
    [dispatch],
  );

  useEffect(() => {
    return auth?.auth.onAuthStateChanged(onAuthChangeFunction);
  }, [auth?.auth, onAuthChangeFunction]);
};
